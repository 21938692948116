import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserModel } from '../models/user.model';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	roles?: string[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;

	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Full screen
	public fullScreen: boolean = false;

	MENUITEMS: Menu[] = [
		{
			headTitle1: 'General',
			headTitle2: 'Users and companies.',
		},
		{
			title: 'Company Settings',
			icon: 'settings',
			type: 'company-modal',
			roles: [UserModel.ROLE_TENANT]
		},
		{
			title: 'Reception Display',
			path: '/companies/display',
			icon: 'image',
			type: 'link',
			roles: [UserModel.ROLE_ADMIN]
		},
		{
			title: 'Companies',
			path: '/companies/list',
			icon: 'home',
			type: 'link',
			roles: [UserModel.ROLE_ADMIN]
		},
		{
			title: 'Appointments',
			path: '/appointments/list',
			icon: 'calendar',
			type: 'link',
			roles: [UserModel.ROLE_ADMIN]
		},
		{
			title: 'Attendees',
			path: '/attendees/list',
			icon: 'user-check',
			type: 'link',
			roles: [UserModel.ROLE_ADMIN]
		},
		{
			title: 'Users',
			path: '/users/list',
			icon: 'users',
			type: 'link',
			roles: [UserModel.ROLE_ADMIN]
		}
	];

	MEGAMENUITEMS: Menu[] = [];

	LEVELMENUITEMS: Menu[] = [];

	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
	levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);

	constructor(
		private router: Router,
	) {
		this.setScreenWidth(window.innerWidth);
		this.setScreenEvents();
	}

	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	private setScreenEvents() {
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if (evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});

		if (window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
	}

}
