import {BaseModel} from './base.model';
import {CompanyModel} from './company.model';
import {ProfileModel} from './profile.model';

export class UserModel extends BaseModel {

  static ROLE_ADMIN = 'admin';
  static ROLE_TENANT = 'tenant';

  id: number;
  username: string;
  password: string;
  role: string;
  isActive: 0 | 1;

  defaultPhotoUrl: string = './assets/images/user/user.png';

  profile: ProfileModel;
  company: CompanyModel;
  imageFile?: File;

  get childModels(): {} {
    return {
      profile: ProfileModel,
      company: CompanyModel,
    };
  }

  toApi(model?: this): {} {
    return {
      ...super.toApi(model),
      ...((model && model.imageFile) ? {image_file: model.imageFile} : {}),
    };
  }

  get thumbUrl() {
    return this.profile?.image?.baseUrl || this.defaultPhotoUrl;
  }

  get fullname(): string {

      return ( (this.profile?.firstName?.toString() || "")  + ' ' + (this.profile?.lastName?.toString() || "")).trim();
  }

  get isAdmin(): boolean {
    return this.role === UserModel.ROLE_ADMIN;
  }

  static get availableRoles() {
    return [
      {id: UserModel.ROLE_ADMIN, name: 'Admin'},
      {id: UserModel.ROLE_TENANT, name: 'Tenant'},
    ];
  }

}
