import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from "./shared/shared.module";
import {AppRoutingModule} from './app-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastrModule} from 'ngx-toastr';

// for HttpClient import:
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
// for Router import:
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
// for Core import:
import {LoadingBarModule} from '@ngx-loading-bar/core';

import {AuthService} from './shared/services/auth.service';
import {AuthGuard} from './shared/guards/auth.guard';
import {CookieService} from 'ngx-cookie-service';
import {AppComponent} from './app.component';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {AttendeeResolver} from './shared/resolvers/attendee.resolver';
import {AppointmentResolver} from './shared/resolvers/appointment.resolver';
import {AuthInterceptor} from "./shared/interceptors/auth.interceptor";
import {UserService} from "./shared/services/user.service";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

const swalMixins = {
  focusConfirm: false,
  showCancelButton: true,
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-outline-danger',
    cancelButton: 'btn btn-light'
  },
};

function appInitializer(authService: AuthService) {
  return () => {
    return authService.getUserByToken().subscribe();
  };
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    ToastrModule.forRoot(),
    SweetAlert2Module.forRoot({
      provideSwal: () => import('sweetalert2').then(({default: swal}) => swal.mixin(swalMixins))
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    CookieService,
    AttendeeResolver,
    AppointmentResolver,
    // FIXME circular DI
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
