import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {UserModel} from '../models/user.model';
import {AuthModel, AuthResetPassModel} from '../models/auth.model';
import {catchError, finalize, map, shareReplay, switchMap} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  static LOGIN = new EventEmitter<boolean>();
  static LOGOUT = new EventEmitter<boolean>();

  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private readonly _getUserData = new Map<string, Observable<UserModel>>();

  currentUser: Observable<UserModel>;
  isLoading: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private http: HttpClient
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.currentUser = this.currentUserSubject.asObservable();
    this.isLoading = this.isLoadingSubject.asObservable();
  }

  login(email: string, password: string): Observable<AuthModel> {
    this.isLoadingSubject.next(true);

    return this.http.post<AuthModel>(`${environment.apiUrl}/oauth/token`, {
      username: email,
      password,
      grant_type: 'password',
      client_id: environment.apiClientId,
      client_secret: environment.apiClientSecret,
    }).pipe(
      map(response => {
        const auth = new AuthModel().setAuth(response);
        const result = this.setAuthToLocalStorage(auth);
        AuthService.LOGIN.emit(true);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout(): void {
    const auth = this.getAuthFromLocalStorage();

    this.revokeToken(auth.refreshToken).subscribe((revoked)=>{
      if(revoked){
        this.revokeToken(auth.authToken).subscribe((revoked)=>{
          if(revoked){
            AuthModel.logout();
            AuthService.LOGOUT.emit(true);
          }
        });
      }
    })



  }

  getUserByToken(): Observable<UserModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }

    const cacheKey = auth.authToken;
    if (this._getUserData && this._getUserData.get(cacheKey)) {
      return this._getUserData.get(cacheKey);
    }

    this.isLoadingSubject.next(true);
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    const params = {
      expand: 'profile.image,company.image'
    };

    return this.http.get<UserModel>(`${environment.apiUrl}/users/me`, {headers: httpHeaders, params: params})
      .pipe(
        map((user: UserModel) => {
          if (user) {
            this.currentUserSubject.next(new UserModel(user));
          } else {
            this.logout();
          }
          return user;
        }),
        shareReplay(),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  refreshToken(token: string) {
    this.isLoadingSubject.next(true);
    return this.http.post<AuthModel>(`${environment.apiUrl}/oauth/token`, {
      refresh_token: token,
      grant_type: 'refresh_token',
      client_id: environment.apiClientId,
      client_secret: environment.apiClientSecret,
    }).pipe(
      map(response => {
        const auth = new AuthModel().setAuth(response);
        const result = this.setAuthToLocalStorage(auth);
        return result ? auth.authToken : false;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.post<any>(`${environment.apiUrl}/users/forgot-password`, {email})
      .pipe(
        catchError((err) => {
          return of(false);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  resetPassword(model: AuthResetPassModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.post<any>(`${environment.apiUrl}/users/reset-password`, model.toApi())
      .pipe(
        catchError((err) => {
          return of(false);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  getAuthFromLocalStorage(): AuthModel {
    return AuthModel.getAuthFromLocalStorage();
  }

  private setAuthToLocalStorage(auth: AuthModel): boolean {
    return AuthModel.setAuthToLocalStorage(auth);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  revokeToken(token: string) {
    return this.http.post<any>(`${environment.apiUrl}/oauth/revoke`, {
      token: token,
      client_id: environment.apiClientId,
      client_secret: environment.apiClientSecret
    }).pipe(
      map(response => {
        return response.revoked
      })
    );

  }

}
