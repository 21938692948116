export const environment = {
  production: false,
  title: 'Kowerk Dietlikon',
  defaultAppLanguage: 'de',
  logoUrl: '/assets/images/logo/kowerk-logo-dark.png',
  logoDarkUrl: '/assets/images/logo/kowerk-logo.png',
  logoOnlyUrl: '/assets/images/logo/kowerk-logo-dark.png',
  logoOnlyDarkUrl: '/assets/images/logo/kowerk-logo.png',
  homepage: 'https://kowerk.info',
  appVersion: 'v001-zip',
  USERDATA_KEY: 'authf-qaJ9jlkeCy',
  apiUrl: 'https://api.dev.digitalreception.ch/v1',
  apiClientId: 'zip-dev',
  apiClientSecret: 'djenvOh5idgdNUlx4lx4MPkuTA79EjEJ',
  defaultCallerId: '+41 (44) 505 66 33',
  instanceReceptions: [
    {
      id: 'office',
      label: 'Office',
      callerId: '+41 (44) 505 66 33'
    },
    {
      id: 'warehouse',
      label: 'Warehouse',
      callerId: '+41 (44) 505 11 66'
    },
  ]
};
